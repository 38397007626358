export default class SalaryInquiriesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.year = "";
    this.month = "";
    // this.textSearch = "";
  }
  fillData(data) {
    this.year = data.year;
    this.month = data.month;
    // this.textSearch = data.textSearch;
  }
}
