<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off" method="post" @submit.prevent="addSalaryInquiry()">
      <div class="my-card">
        <div class="row">
          <CustomInputInt
            :className="'col-md-6'"
            :id="'month'"
            :value="salaryInquiryData.month"
            :title="$t('month')"
            :imgName="'date.svg'"
            :maxlength="2"
            v-on:changeValue="salaryInquiryData.month = $event"
          />
          <CustomInputInt
            :className="'col-md-6'"
            :id="'year'"
            :value="salaryInquiryData.year"
            :title="$t('year')"
            :imgName="'year.svg'"
            :maxlength="4"
            v-on:changeValue="salaryInquiryData.year = $event"
          />
        </div>
      </div>

      <b-button v-b-toggle.employees class="btn btn-lg btn-collapse">
        {{ $t("employees") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="employees">
        <div v-if="employeesArray" class="table-container">
          <table class="my-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="allemployeesStatus"
                    @click="employeeSelectAll"
                    v-model="salaryInquiryData.allemployeesStatus"
                    class="checkbox"
                  />
                  <label class="mt-1 ml-2" for="allemployeesStatus">{{
                    $t("selectAll")
                  }}</label>
                </th>
                <th>#</th>
                <th>{{ $t("employeeCode") }}</th>
                <th>{{ $t("employeeName") }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(employee, index) in employeesArray">
                <tr :key="index">
                  <td>
                    <input
                      type="checkbox"
                      v-model="salaryInquiryData.employeesTokensList"
                      :value="employee.employeeToken"
                      @click="employeeSelect"
                      class="checkbox"
                    />
                  </td>
                  <td>{{ ++index }}</td>
                  <td>{{ isDataExist(employee.employeeCode) }}</td>
                  <td class="cell-with-image">
                    <img
                      class="item-img-table"
                      :src="employee.employeeImagePath"
                      :onerror="`this.src='${defaultImg}'`"
                    />
                    {{ isDataExist(employee.employeeNameCurrent) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <ExceptionWithImg v-else :msg="exceptionMsgEmployee" />
      </b-collapse>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'SalaryInquiries' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { setDataMultiLang, isDataExist } from "@/utils/functions";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";

export default {
  name: "SalaryInquiryForm",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInputInt,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
    };
  },
  props: {
    salaryInquiryData: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    employeesArray: {
      type: Array,
      default: () => [],
    },
    exceptionMsgEmployee: {
      type: String,
      default: "",
    },
  },
  methods: {
    employeeSelectAll() {
      this.salaryInquiryData.allemployeesStatus =
        !this.salaryInquiryData.allemployeesStatus;

      this.salaryInquiryData.employeesTokensList = [];
      if (this.salaryInquiryData.allemployeesStatus) {
        for (let employee in this.employeesArray) {
          this.salaryInquiryData.employeesTokensList.push(
            this.employeesArray[employee].employeeToken
          );
        }
      }
    },
    employeeSelect() {
      setTimeout(() => {
        if (
          this.employeesArray.length ==
          this.salaryInquiryData.employeesTokensList.length
        )
          this.salaryInquiryData.allemployeesStatus = true;
        else this.salaryInquiryData.allemployeesStatus = false;
      }, 500);
    },

    async addSalaryInquiry() {
      this.$emit("addSalaryInquiry");
    },
    setDataMultiLang,
    isDataExist,
  },
  watch: {},
  async created() {},
};
</script>
