import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import SalaryInquiriesFilter from "./SalaryInquiriesFilter";

export default class SalaryInquiry {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.year = "";
    this.month = "";
    this.allemployeesStatus = false;
    this.employeesTokensList = [];
  }

  async getEmployeesStatisticsSalary(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new SalaryInquiriesFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeesEnquirySalaries/GetAllEmployeesEnquirySalaries?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&year=${filterData.year}&month=${filterData.month}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addSalaryInquiry(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      year: this.year,
      month: this.month,
      allemployeesStatus: this.allemployeesStatus,
      employeesTokensList: this.allemployeesStatus
        ? []
        : this.employeesTokensList,
    };

    try {
      let response = await axios.post(
        `/EmployeesEnquirySalaries//AddEmployeesEnquirySalaries`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
