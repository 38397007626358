export default class AllEmployeesStatisticsSalary {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.totalAbsenceTimePrice = 0;
    this.totalAllowance = 0;
    this.totalAllowancesMonth = 0;
    this.totalDutactionGrant = 0;
    this.totalEarlyDepartureTimePrice = 0;
    this.totalIncreaseGrant = 0;
    this.totalInsuranceAmount = 0;
    this.totalKilometer = 0;
    this.totalLateAttendaceTimePrice = 0;
    this.totalLostTimePrice = 0;
    this.totalOfficialHolidayTimePrice = 0;
    this.totalOverTimePrice = 0;
    this.totalPaid = 0;
    this.totalPoints = 0;
    this.totalPriceKilometer = 0;
    this.totalRemaining = 0;
    this.totalSalary = 0;
    this.totalTaxAmount = 0;
    this.totalUnknownWorkingTimePrice = 0;
    this.totalWorkTimePrice = 0;
    this.totalWorkTimesPrice = 0;
  }
  fillData(data) {
    this.totalAbsenceTimePrice = data.totalAbsenceTimePrice;
    this.totalAllowance = data.totalAllowance;
    this.totalAllowancesMonth = data.totalAllowancesMonth;
    this.totalDutactionGrant = data.totalDutactionGrant;
    this.totalEarlyDepartureTimePrice = data.totalEarlyDepartureTimePrice;
    this.totalIncreaseGrant = data.totalIncreaseGrant;
    this.totalInsuranceAmount = data.totalInsuranceAmount;
    this.totalKilometer = data.totalKilometer;
    this.totalLateAttendaceTimePrice = data.totalLateAttendaceTimePrice;
    this.totalLostTimePrice = data.totalLostTimePrice;
    this.totalOfficialHolidayTimePrice = data.totalOfficialHolidayTimePrice;
    this.totalOverTimePrice = data.totalOverTimePrice;
    this.totalPaid = data.totalPaid;
    this.totalPoints = data.totalPoints;
    this.totalPriceKilometer = data.totalPriceKilometer;
    this.totalRemaining = data.totalRemaining;
    this.totalSalary = data.totalSalary;
    this.totalTaxAmount = data.totalTaxAmount;
    this.totalUnknownWorkingTimePrice = data.totalUnknownWorkingTimePrice;
    this.totalWorkTimePrice = data.totalWorkTimePrice;
    this.totalWorkTimesPrice = data.totalWorkTimesPrice;
  }
}
